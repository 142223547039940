/*

  Custom Breadcrumb
  ----------------------------------

*/

.custom-breadcrumb{
  margin-bottom: calc(#{$paragraph-margin-bottom} / 1.5);

  a{
    color: $body-color;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }

  .breadcrumb-item:last-of-type a{
    color: $link-color;
  }

}