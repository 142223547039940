/*

  Custom Forms
  ----------------------------------
  Extends @import "~bootstrap/scss/custom-forms";

*/

// Custom Select
// Removing rounded corners on the custom dropdown in osx
.custom-select {
  -webkit-appearance: none;
  background-position: 96% 50%;
  transition: $input-transition;
  font-weight: $font-weight-normal;
  line-height: 1.2;
  background-size: 12px;

  &:focus{
    background-color: $input-focus-bg;
  }

  &:disabled{
    opacity: 0.5;
  }

}


// Custom Range
// Removing browser styles for range slider
.custom-range {
  -webkit-appearance: none;
}


// Custom File
.custom-file{
  font-weight: $font-weight-normal;

  .custom-file-input,
  .custom-file-label,
  .custom-file-label::after{
    line-height: 1;
  }

}

// Ensuring that the custom input type file is the same height as all the other fields
.custom-file-label,
.custom-file-label::after{
  height: $input-height;
  line-height: 0;
  font-weight: $font-weight-normal;
}
.custom-file-label::after{
  top:-2px;
}

.custom-file-lg{
  height: $input-height-lg;
  line-height: 0;

  .custom-file-input,
  .custom-file-label,
  .custom-file-label::after{
    height: $input-height-lg;
    line-height: 1.5;
  }

}
.custom-file-sm{
  height: $input-height-sm;
  line-height: 0;

  .custom-file-input,
  .custom-file-label,
  .custom-file-label::after{
    height: $input-height-sm;
    line-height: 0.3;
  }

}


// Custom Control Label
// Box shadows are silly, take 'em off.
.custom-control-label {
  box-shadow: none;
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  &:before, &:after{
    border-radius: 0.5rem;
    box-shadow: none !important;

    @media (max-width: 560px){
      top:3px;
    }

  }
  &:before{
    //border: 2px solid $secondary;
  }
}

// Add some spacing around the custom fields
.custom-checkbox,
.custom-radio{
  min-height: (1.5rem * $line-height-base);
}
