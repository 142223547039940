/*

  Breadcrumb
  ----------------------------------
  Extends @import "~bootstrap/scss/breadcrumb";

*/

.breadcrumb{
  font-size: 0.875rem;
  margin-bottom: 0px;
  line-height: 1;
  li{
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.65rem;
    &:before{
      position: relative;
      right: -1px;
    }
  }
  a{
    text-decoration: none;
  }
}
