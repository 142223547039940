/*

  Site Header
  ----------------------------------
  Global header.

*/

.site-header{
  text-align: center;
  border-bottom: 1px solid $gray-100;
  padding: 0.5rem 0.5rem 0.35rem 0.5rem;
  height: 70px;
  background: white;
  position: relative;
  z-index: 90;
  overflow: hidden;

  &--hasmenu {
    border-bottom: 0;

    & + .site-main-menu--opened {
      &:before {
        background: $gray-100;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 169px;
        width: 100%;
        z-index: 9999999;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    height: 170px;
    padding-left: 0px;
    padding-right: 0px;
  }

}

// Header Sponsors
.site-header-sponsors{
  position: absolute;
  left: 25px;
  top: 5px;

  .sponsors img{
    max-width: 120px;
    max-height: 80px;
    object-fit: contain;
  }

}

// Header Logo
.site-header-logo-container{
  display: inline-block;
  height: 58px;
  @include media-breakpoint-up(lg) {
    height: 100px;
  }
}
.site-header-logo{
  display: inline-block;
  vertical-align: top;
  width: 125px;
  transition: all 0.8s ease-in-out;
  @include media-breakpoint-up(lg) {
    width: 215px;
  }
}

// Header Content Link
.site-header-contact{
  font-size: 10px;
  padding: 10px 25px;
  position: absolute;
  right: 25px;
  top: 37px;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

}

// Header Links
.site-header-links{
  display: inline-block;
  line-height: 1;
  ul{
    margin: 0px;
    padding: 0px;
  }
  li{
    display: inline-block;
    margin: 0px;
    line-height: 1;
  }
  a{
    color: $primary;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: 25px 24px 24px;
    text-decoration: none;
    display: inline-block;
    position: relative;

    // arrow
    &:before, &:after{
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      z-index: 2;
      opacity: 0;
    }

    &:before{
      @include triangle('top', #150a40, 8px, 8px);
      z-index: 3;
      bottom: -2px;
    }

    &:after{
      @include triangle('top', #150a40, 8px, 8px);
    }

    &:hover, &:focus{
      text-decoration: underline;
    }
    &.active{
      text-decoration: underline;
      font-weight: 800;
      &:before, &:after{
        opacity: 1;
      }
    }
  }
}

// Header Menu toggle
.site-header-toggle-menu{
  padding: 0px;
  width: 27px;
  position: absolute;
  top: 25px;
  left: 15px;
  transition: all 0.8s ease-in-out;
  -webkit-border-radius: 0;

  @include media-breakpoint-up(lg) {
    position: static;
    width: 80px;
    padding: 22px 23px;
  }

}

// Header Menus Section
.site-header-menus-section{
  //display: none;

  .menu-section{
    display: inline-block;
    @include media-breakpoint-up(lg) {
      float: left;
      position: relative;
      //height: 65px;
      border: 1px solid #e6e7e8;
      //padding: 15px 30px;
      &:nth-of-type(2){
        border-left: none;
        //border-right: none;
        width: calc(100% - 163px);
      }
      &:last-of-type{
        border-top: 1px solid #e6e7e8;
        width: 80px;
        border-right: none;
      }
    }
  }
  //
  //.search-section{
  //  background: white;
  //  transition: all 0.8s $easeInOutQuint;
  //  .g-search-is-open &{
  //    transform: translateX(-400px);
  //  }
  //}

}
