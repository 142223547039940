/*

  Tables
  ----------------------------------
  Extends @import "~bootstrap/scss/tables";

*/

.table{
  color: $table-color;

  thead th{
    font-weight: $font-weight-semi-bold;
    border-top-width: 2px;
  }

}
.table-dark {
  color: $table-dark-color;
}

.table {
  .thead-dark {
    th {
      background-color: $table-dark-thead-bg;
      color:white;
    }
  }
}

.table-thead-dark {
  th {
    background-color: $table-dark-thead-bg;
    color:white;
  }
}

.table-sm {
  th,
  td {
    font-size: 85%;
  }
}
