/*

  Site Footer
  ----------------------------------
  Global footer.

*/

.site-footer{
  background: #142243;
  color: white;
  text-align: center;
}

// Footer Copyright
.site-footer-copyright{
  margin: 0px;
  font-size: 0.85rem;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

}

// Footer Menu
.site-footer-menu{
  margin-bottom: 15px;
  font-size: 0.85rem;

  @include media-breakpoint-up(md) {
    text-align: right;
    margin-bottom: 0px;
  }

  a{
    color: #ffffff;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1.71px;
    margin: 5px;
    display: block;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin: 0px 0px 0px 60px;
    }

    @include media-breakpoint-up(lg) {
      &:hover, &:focus{
        text-decoration: underline;
      }
    }

  }

}