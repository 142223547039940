/*

  Links Dropdown
  ----------------------------------

*/

// Dropdown Container
.links-dropdown{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: $paragraph-margin-bottom;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1.7rem;
  }

  h1{
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0px;
    }
  }

}

// Dropdown Trigger
.links-dropdown-trigger{
  padding-right: 0;
  padding-left: 0;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  top: -2px;
  z-index: 15;
  background: white;

  .links-dropdown-open &{
    background: $primary;
    color: white;
  }

  svg{
    display: inline-block;
    vertical-align: top;
    width: 14px;
    height: 16px;
  }

  path{
    fill: $primary !important;
    transition: all .15s ease-in-out;
  }

  &:hover, &:focus{
    background-color: $primary;
    color:white;
    path{
      fill: white !important;
    }
  }

}
.links-dropdown-trigger-arrow{
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  transition: all .15s $easeInOutQuint;

  .links-dropdown-open &{
    transform: translateY(-50%) rotateX(180deg);
  }

}

// Dropdown List
.links-dropdown-list{
  position: absolute;
  top: 0px;
  left: 15px;
  width: calc(100% - 30px);
  overflow: hidden;
  overflow-y: auto;
  max-height: 250px;
  background: #fff;
  border: 1px solid #e6e7e8;
  border-radius: 20px;
  z-index: 10;
  padding-top: 35px;
  transition: all .15s $easeInOutQuint;
  transform: translateY(-10px);
  pointer-events: none;
  opacity: 0;
  box-shadow: 0px 11px 27px -16px black;

  .links-dropdown-open &{
    transform: translateY(0px);
    pointer-events: auto;
    opacity: 1;
  }

  a{
    display: block;
    width: 100%;
    padding: 5px 20px;
    border-bottom: 1px solid $gray-100;
    text-decoration: none;
    color: $primary;
    font-size: 0.85em;
    font-weight: 600;
    text-align: center;
    &:last-of-type{
      border-bottom: none;
    }
    &:hover, &:focus{
      color: $warning;
    }
  }

}