/*

  Lazysizes
  ----------------------------------
  Loading in the third party CSS

*/

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
