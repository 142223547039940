/*

  Radio Awards Menu
  ----------------------------------

*/

.radio-awards-menu{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: url('/web/images/radio-awards-menu-bg.jpg');
  background-size: cover;
  padding: 0.85rem 0px;
  text-align: center;
  //margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    //margin-bottom: 2.5rem;
  }

  svg{
    width: 180px;
    height: 97px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  +.custom-breadcrumb,
  +.site-sidebar-layout{
    margin-top: 1.5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.5rem;
    }
  }

}

.radio-awards-menu-links{

  a{
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 10px 10px 10px;
    transition: all .15s ease-in-out;

    @include media-breakpoint-up(md) {
      padding: 20px 40px 20px 0px;
      font-size: 0.85rem;
    }

    &.active{
      font-weight: bold;
    }

    &:hover, &:focus{
      color: $warning;
    }

  }

}


.radio-awards-menu-btns{

  .btn{
    display: inline-block;
    text-transform: uppercase;
    color: white;
    margin: 10px;
    width: 100%;
    max-width: 250px;
    padding-left: 0px;
    padding-right: 0px;
    border-width: 1px;
    font-weight: 400;
    @include media-breakpoint-up(md) {
      margin: 20px 40px 00px 0px;
    }

    &.login-ramb{
      @include media-breakpoint-up(md) {
        max-width: 150px;
      }
    }

  }

}
