/*

  Buttons
  ----------------------------------
  Extends @import "~bootstrap/scss/buttons";

*/

.btn{
  text-decoration: none;
  white-space: normal;
  -webkit-appearance: none;
  &[class*="outline"]{
    border-width: 2px;
  }
}