/*

  Tiny Slider
  ----------------------------------
  Loading in the third party CSS

*/
@import url('~tiny-slider/dist/tiny-slider.css');

.tns-outer{
  position: relative;
}

.tns-nav{
  text-align: center;
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 5;
  button{
    width: 12px;
    height: 12px;
    border: 0;
    border-radius: 100%;
    display: inline-block;
    padding: 0px;
    margin-left: 3px;
    margin-right: 3px;
    &.tns-nav-active{
      background: $warning;
    }
  }
}

.tns-lazy-img {
  transition: opacity 0.4s;
  opacity: 0;
}