/*

  Theme Variables
  ----------------------------------

  Variables should follow the `$component-state-property-size` formula for
  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

  Bootstrap variable overrides should be defined here without the !default setting.
  See https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults

*/


$brand: (
  "blue":           #243c75,
  "light-blue":     #14b5d2,
  "green":          #c9d565,
  "purple":         #63438c,
  "gold":           #a69568
);


// Colours
// ----------------------------------
$white:     #ffffff;
$gray-100:  #e6e7e8;
$gray-200:  #d3d3d3;
$gray-300:  #aeaeae;
$gray-400:  #6d6f71;
$gray-500:  #424242;
$gray-600:  #313131;
$gray-700:  #2e2e2e;
$gray-800:  #262626;
$gray-900:  #222325;
$grays:     (100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900);
$gray:      #bbbbbb;
$warning:   map_get($brand, "gold");
$danger:    #af4545;
$success:   map_get($brand, "green");
$primary:   map_get($brand, "blue");
$secondary: map_get($brand, "purple");
$light:     $gray-200;
$dark:      $gray-500;
$info:      map_get($brand, "light-blue");

// Body
// ----------------------------------
$body-bg:       $white;
$body-color:    $gray-900;


// Typography
// ----------------------------------
$font-family-sans-serif:    'Montserrat', "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-weight-thin:          100;
$font-weight-light:         300;
$font-weight-normal:        400;
$font-weight-semi-bold:     500;
$font-weight-bold:          600;
$font-weight-black:         700;
$font-weight-extra-black:   800;

$font-weight-base:          $font-weight-normal;
$font-size-base:            1rem;
$font-size-sm:              ($font-size-base * .85);
$font-size-md:              ($font-size-base * 1.25);
$font-size-lg:              ($font-size-base * 1.5);

$headings-font-weight:      $font-weight-black;
$headings-line-height:      1;
$h1-font-size:              $font-size-base * 2;
$h2-font-size:              $font-size-base * 1.75;
$h3-font-size:              $font-size-base * 1.5;
$h4-font-size:              $font-size-base * 1.25;
$h5-font-size:              $font-size-base * 1.15;
$h6-font-size:              $font-size-base;


$blockquote-small-color:    $gray-400;
$blockquote-font-size:      $font-size-lg;
$text-muted:                $gray-300;
$hr-border-color:           #bbb;
$small-font-size:           65%;
$line-height-base:          1.7;
$paragraph-margin-bottom:   1.5rem;

$display1-weight:           $font-weight-normal;
$display2-weight:           $font-weight-normal;
$display3-weight:           $font-weight-normal;
$display4-weight:           $font-weight-normal;

$display1-size:             4rem;
$display2-size:             3rem;
$display3-size:             1.7rem;
$display4-size:             1.4rem;

$display-line-height:       1.2;


// Links
// ----------------------------------
$link-color:                map_get($brand, "purple");
$link-decoration:           underline;
$link-hover-color:          map_get($brand, "blue");
$link-hover-decoration:     underline;


// Code
// ----------------------------------
$code-color:  #654d4d;
$code-bg:     $gray-100;
$kbd-color:   #654d4d;
$kbd-bg:      $gray-100;
$pre-color:   #654d4d;


// Buttons
// ----------------------------------
$input-btn-padding-y:         0.75rem;
$input-btn-padding-x:         3.5rem;
$input-btn-line-height:       1;
$btn-border-radius:           24px;

$input-btn-padding-y-sm:      0.55rem;
$input-btn-padding-x-sm:      3rem;
$input-btn-line-height-sm:    1;
$btn-border-radius-sm:        24px;

$input-btn-padding-y-lg:      1rem;
$input-btn-padding-x-lg:      4rem;
$input-btn-line-height-lg:    1;
$btn-border-radius-lg:        48px;

$btn-font-weight:             $font-weight-black;
$btn-box-shadow:              inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($gray-900, 0.075);
$btn-focus-box-shadow:        0 0 0 1px rgba($secondary, 0.25);
$btn-active-box-shadow:       inset 0 3px 5px rgba($gray-900, 0.125);
$btn-link-disabled-color:     $gray-600;
$btn-block-spacing-y:         0.5rem;
$btn-transition:              all 0.15s ease-in-out;


// Form
// ----------------------------------

$input-padding-y:             1rem;
$input-padding-x:             1rem;
$input-line-height:           1.5;
$input-border-radius:         0rem;
$input-height-inner:          45px;
$input-height:                48px;

$input-padding-y-sm:          0.85rem;
$input-padding-x-sm:          0.85rem;
$input-line-height-sm:        1.5;
$input-border-radius-sm:      0rem;
$input-height-inner-sm:       35px;
$input-height-sm:             38px;

$input-padding-y-lg:          1.25rem;
$input-padding-x-lg:          1.25rem;
$input-line-height-lg:        1.5;
$input-border-radius-lg:      0rem;
$input-height-inner-lg:       55px;
$input-height-lg:             58px;

$input-color:                 black;
$input-placeholder-color:     black;
$input-bg:                    $white;
$input-focus-bg:              #e5e5e5;
$input-disabled-bg:           $white;
$input-group-addon-bg:        $secondary;
$input-border-width:          1px;
$input-border-color:          #e5e5e5;
$input-btn-focus-box-shadow:  0 0 0 0px $white;
$input-focus-border-color:    $gray-400;
$input-focus-box-shadow:      $input-btn-focus-box-shadow;
$input-transition:            all 0.4s ease-in-out;
$form-group-margin-bottom:    1.5rem;


// Custom Forms
// ----------------------------------
$custom-control-gutter:                     3rem;
$custom-control-spacer-x:                   2.5rem;
$custom-control-indicator-size:             2rem;
$custom-control-indicator-bg:               $input-border-color;
$custom-control-indicator-checked-color:    $white;

$custom-checkbox-indicator-border-radius:   6px;

$custom-select-bg:                          $white;
$custom-select-bg-size:                     16px; // In pixels because image dimensions
$custom-select-indicator:                   url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 24 12"><g transform="translate(-1216 -427)"><path id="_x37_956a" d="M1228 439l-12-12h24l-12 12z"/></g></svg>');
$custom-select-border-width:                1px;
$custom-select-border-color:                $input-border-color;
$custom-select-border-radius:               0rem;
$custom-select-focus-border-color:          $input-focus-border-color;
$custom-select-focus-box-shadow:            $input-btn-focus-box-shadow;
$custom-select-padding-y:                   0.25rem;
$custom-select-padding-x:                   1rem;
$custom-select-indicator-padding:           0.7rem;

$custom-range-track-height:                 0.2rem;
$custom-range-track-bg:                     $secondary;
$custom-range-track-border-radius:          0.5rem;
$custom-range-track-box-shadow:             none;

$custom-file-padding-x:                     $input-padding-x;
$custom-file-border-width:                  1px;
$custom-file-border-color:                  $input-border-color;
$custom-file-button-bg:                     $secondary;
$custom-file-button-color:                  $white;


// Tables
// ----------------------------------
$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .65rem;
$table-bg:                    $gray-100;
$table-color:                 $gray-900;
$table-accent-bg:             $gray-200;
$table-hover-bg:              $gray-300;
$table-active-bg:             $table-hover-bg;
$table-border-width:          1px;
$table-border-color:          $gray-900;
$table-head-bg:               $gray-200;
$table-head-color:            $gray-900;

$table-dark-bg:               $gray-800;
$table-dark-thead-bg:         $gray-900;

$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($gray-900, 7.5%);
$table-dark-color:            $white;


// Figures
// ----------------------------------
$figure-caption-color:  $gray-500;


// Alert
// ----------------------------------
$alert-padding-y:       0.85rem;
$alert-padding-x:       0.85rem;
$alert-margin-bottom:   0px;
$alert-border-radius:   0px;
$alert-border-width:    0px;


// Breadcrumbs
// ----------------------------------
$breadcrumb-bg:               transparent;
$breadcrumb-divider-color:    #222325;
$breadcrumb-active-color:     #222325;
$breadcrumb-border-radius:    0px;
$breadcrumb-item-padding:     0.5rem;
$breadcrumb-padding-y:        0rem;
$breadcrumb-padding-x:        0rem;


// Card
// ----------------------------------
$card-border-radius:     0px;
$card-border-color:      rgba($white, .125);
$card-bg:                transparent;


// List Group
// ----------------------------------

$list-group-bg:                     $gray-500;
$list-group-border-radius:          0px;
$list-group-action-color:           $gray-300;
$list-group-action-hover-color:     $gray-800;


// Close
// ----------------------------------

$close-font-weight:                 normal;
$close-color:                       white;
$close-text-shadow:                 none;


// Modals
// ----------------------------------

$modal-content-bg:                  $gray-800;
$modal-content-border-color:        $gray-300;
$modal-content-border-radius:       0px;
$modal-backdrop-bg:                 #050505;
$modal-backdrop-opacity:            .8;
$modal-header-border-color:         $gray-300;
$modal-lg:                          1024px;
$modal-md:                          600px;
$modal-sm:                          400px;


// Popover
// ----------------------------------

$popover-bg:                        $gray-800;


// Jumbotron
// ----------------------------------

$jumbotron-bg:                      $gray-900;


// Navs
// ----------------------------------

$nav-tabs-link-active-color:        $gray-100;


// Pagination
// ----------------------------------

$pagination-color:                  $gray-400;
$pagination-bg:                     transparent;
$pagination-border-width:           0px;
$pagination-border-color:           transparent;
$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $gray-400;
$pagination-active-bg:              transparent;
$pagination-active-border-color:    transparent;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            transparent;
$pagination-disabled-border-color:  transparent;
