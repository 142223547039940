/*

  Forms
  ----------------------------------
  Extends @import "~bootstrap/scss/forms";

*/

// Removing rounded corners on the default dropdown in osx
select{
  &.form-control{
    padding-bottom: 0px;
    padding-top: 0px;
    &:not([size]):not([multiple]) {
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#000000' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
      background-position: 99% 50%;
      background-repeat: no-repeat;
    }
  }
}

// Increasing the height of textarea, its way to small...
textarea{
  &.form-control{
    height: calc(3 * #{$input-height});
  }
  &.form-control-lg{
    height: calc(3 * #{$input-height-lg});
  }
  &.form-control-sm{
    height: calc(3 * #{$input-height-sm});
  }
}

// Ensuring that the default input type file is the same height as all the other fields
.form-control-file{
  height: $input-height;
  line-height: calc(#{$input-height} - 4px);
  &.form-control-file-lg{
    height: $input-height-lg;
    line-height: calc(#{$input-height-lg} - 4px);
  }
  &.form-control-file-sm{
    height: $input-height-sm;
    line-height: calc(#{$input-height-sm} - 4px);
  }
}

// Overriding default styles for the first <label> inside a .form-group
.form-group > label:first-of-type{
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  //margin-bottom: 5px;
}


// Overriding .form-control defaults
.form-control{
  font-weight: $font-weight-normal;

  &:disabled,
  &[readonly] {
    opacity: 0.3;
  }

}
