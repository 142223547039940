/*********************************************
    Site Main Menu
********************************************/
.site-main-menu{
  float: left;
  width: 100%;
  height: 100vh;
  border-top: 1px solid $gray-300;
  background-color: white;
  z-index: 89;
  position: fixed;
  top: 0px;
  left:0px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.8s $easeInOutQuint;
  text-align: left;
  padding: 90px 20px 20px 20px;
  opacity: 0;
  pointer-events: none;
  max-width: 580px;
  transform: translate3d(-100%, 0px, 0);

  @include media-breakpoint-up(lg) {
    padding: 209px 40px 40px 30px;
  }

  ul{
    margin: 0px;
    padding: 0px;
  }

  li{
    list-style-type: none;
    margin: 0px;
    line-height: 1;
    position: relative;
  }

  // State: Opened
  &.site-main-menu--opened{
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0px, 0px, 0);

    .site-main-menu__search,
    .site-main-menu__level-one {
      opacity: 1;
    }

  }

}

// If the main menu is opened...
.g-main-menu-is-open {
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    overflow: auto;
  }

  &:before{
    opacity: 1;
  }

}

// Main menu items
.site-main-menu-items{
  display: inline-block;
  width: 100%;
  vertical-align: top;
  border-bottom: 1px solid #e6e7e8;
  padding-top: 15px;
  line-height: 1;

  &:first-of-type{
    padding-top: 0px;
  }

  &:nth-last-of-type(1){
    border-bottom: none;
    margin-bottom: 15px;
  }

  h3{
    color: $gray-900;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 15px;

    a{
      color: $gray-900;
      font-weight: 600;
    }

  }

  li{
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  a{
    text-decoration: none;
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
    font-size: 0.75rem;

    @include media-breakpoint-up(lg) {
      &:hover, &:focus{
        text-decoration: underline;
      }
    }

    &.active{
      text-decoration: underline;
      font-weight: 800;
    }

  }

}

// Close button inside the menu
.site-main-menu-close{
  padding: 0px;
  width: 27px;
  position: absolute;
  top: 35px;
  transition: all 0.8s ease-in-out;

  @include media-breakpoint-up(lg) {
    width: 80px;
    top: 115px;
    left:0px;
    padding: 22px 23px;
  }

}