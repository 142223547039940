/*

  Layout
  ----------------------------------
  Custom layout rules.

*/


// ----------------------------------


body{
  background-image: url('/web/images/bg.jpg');
  background-position: 50% 25%;
  &:before{
    content: '';
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 80;
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    opacity: 0;
    transition: all 0.8s ease-in-out;
  }
  &.page-winners{
    background: none;
    .site-main{
      background-image: url('/web/images/pattern.webp');
      background-position: top center;
      background-size: 65%;
      background-repeat-y: no-repeat;
    }
  }
}



.site-main{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 1.5rem 0px 1.5rem 0px;
  min-height: calc(100vh - 186px);
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding: 3rem 0px 3rem 0px;
    min-height: calc(100vh - 241px);
  }

}

.site-sidebar-layout{
  >.container .container{
    padding: 0px;
  }
}

.site-sidebar-layout-main,
.site-sidebar-layout-aside{
   >section:first-child{
     padding-top: 0px;
   }
 }

.site-sidebar-layout-aside{
  >*{
    margin-bottom: 1.5rem !important;
  }
}

.site-sidebar-layout-main{
  @include media-breakpoint-up(lg) {
    padding-right: 80px;
  }
}