/*

  Homepage Carousel
  ----------------------------------

*/


.image-carousel.image-carousel--homepage {
  background: none;

  .image-carousel-item {
    position: relative;
    text-align: center;
    background: #fff;
  }

  .image-carousel-image {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-height: 435px;
    object-fit: cover;
    font-family: 'object-fit: cover; object-position: center top;';
    object-position: center;

    @include media-breakpoint-up(md) {
      max-height: 410px;
      min-height: 410px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 580px;
      min-height: 580px;
    }
  }

  .image-carousel-content {
    position: relative;
    margin: 30px auto 20px;
    padding: 0 15px;
    text-align: left;
    width: 100%;

    @include media-breakpoint-up(md) {
      color: #fff;
      left: 0%;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      top: 45px;
    }

    @include media-breakpoint-up(lg) {
      left: 0%;
      top: 100px;
      padding-left: 100px;
      padding-right: 100px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0px;
    }

    .btn{
      margin-bottom: 10px;
      margin-right: 10px;
    }

    h1, h2{
      color: $primary;
      padding-bottom: 15px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        color: #fff;
      }
    }

  }

  .image-carousel-text {
    //max-width: 540px;
    @include media-breakpoint-up(md) {
      max-width: 560px;
      width: 100%;
    }
  }



  .image-carousel-buttons {
    .btn {
      display: block;
      margin-bottom: 18px;
      text-transform: uppercase;

      &:first-of-type {
        @extend .btn-warning;
      }
    }

    @include media-breakpoint-up(md) {
      white-space: nowrap;

      .btn {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 25px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 10px;
    }
  }

  .tns-ovh {
    border-bottom: 1px solid #e8e8ea;

    @include media-breakpoint-up(md) {
      border: 0;
    }
  }

  .tns-nav {
    display: block;
    bottom: auto;
    top: 15px;

    @include media-breakpoint-up(md) {
      bottom: 15px;
      top:auto;
    }

    button{
      background: white;
      &.tns-nav-active{
        background: $warning;
      }
    }

  }

  .tns-controls {
    position: absolute;
    top: 0%;
    left:0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: none;
    pointer-events: none;
    

    @include media-breakpoint-up(lg) {
      top: 0%;
      display: block;
    }

    button {
      pointer-events: auto;
      position: absolute;
      cursor: pointer;
      outline: none;
      padding: 0;
      margin: 0;
      border: 0;
      color: $warning;
      //font: 1.6em sans-serif;
      //font-size: 40px;
      line-height: 1;
      transition: background-color .4s ease;
      position: absolute;
      top: 10px;
      transition: opacity 0.3s;
      border-radius: 100%;
      width: 60px!important;
      height: 60px!important;
      text-align: center;
      background: white;
      opacity: 0.3;
      transform: scale(0.7);


      svg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0) scale(.7);
      }

       g, polyline {
        stroke: rgba(0,0,0,.5);
      }

      &:hover {
        opacity: 0.8;
      }

      &[data-controls="prev"] {
        left: 20px;
      }

      &[data-controls="next"] {
        right: 20px;
      }

      @include media-breakpoint-up(md) {
        top: 50%;
        transform: translateY(-50%);
      }

    }
  }
}
