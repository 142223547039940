/*

  Winners
  ----------------------------------

*/

// Wrapper
.winners-app{
  margin-top: 30px;

  aside{
    @include media-breakpoint-up(lg) {
      padding-right: 60px;
    }
    p{
      margin-bottom: 5px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
    }
  }

  section{
    @include media-breakpoint-up(lg) {
      padding-left: 40px;
    }
  }

  .form-group{
    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
      &:first-of-type{
        margin-bottom: 4rem;
      }
    }

    >label:first-of-type{
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      color: $secondary;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #979797;
      width: 100%;
    }
  }

  .form-control,
  .custom-select{
    border-color: $secondary;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }

  .custom-checkbox{
    padding-left: 2rem;
  }
  .custom-control-label{
    font-weight: 400;
    padding-top: 2px;
  }
  .custom-control-label::before,
  .custom-control-label::after{
    width: 20px;
    height: 20px;
    border-radius: 0;
    margin-top: 5px;
    left: -2rem;
  }
  .custom-control-label::before{
    background-color: #ffffff;
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    border-color: $secondary;
    background-color: $secondary;
  }

  .winners-app__filter--station,
  .winners-app__filter--category{
    input{
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M14.174 12.66a7.691 7.691 0 0 0 1.368-4.389A7.768 7.768 0 0 0 7.771.5a7.768 7.768 0 0 0-7.18 10.745 7.768 7.768 0 0 0 7.18 4.797 7.944 7.944 0 0 0 4.408-1.368l4.826 4.826L19 17.505l-4.826-4.845Zm-1.235-4.384a5.176 5.176 0 0 1-5.168 5.173c-2.85 0-5.187-2.32-5.187-5.173a5.184 5.184 0 0 1 5.187-5.192c2.85 0 5.168 2.34 5.168 5.192Z' fill='%2363438C'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='translate(0 .5)' d='M0 0h19v19H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 5% 50%;
      padding-left: 45px;
    }
  }

}

// Results Message
.winners-app__results{
  font-size: 1rem;
  line-height: 1.7;
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    margin-bottom: 55px;
  }
}

// Winner Item
.winners-app__item{
  margin-bottom: 20px;
  border-bottom: 1px solid #979797;
  @include media-breakpoint-up(lg) {
    margin-bottom: 40px;
  }
}
.winners-app__item-header{
  display: flex;
  justify-content: space-between;
  color: $primary;
  font-weight: bold;
  background: rgba(37, 62, 116, 0.1);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  p{
    margin: 0;
  }
}
.winners-app__item-body{
  padding: 15px;
  line-height: 1.5;
}
.winners-app__item-type{
  color: #979797;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  .winners-app__item--winner &{
    color: #A69568;
  }
}