/*

  Forms
  ----------------------------------
  Custom form styles for form defaults and custom bootstrap classes

*/


// Adding Error state to .form-group
.form-group.form-group-validate{
  color: $danger;
  .form-control, .custom-select{
    border-color: $danger;
  }
}

// Submitting a form dynamically...
form{
  transition: all 0.8s ease-in-out;

  // State: Submitting...
  &.submitting-form{
    pointer-events: none;
    opacity: 0.2;
  }

  // State: Submitted
  &.submitted-form{
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
  }

}

// Form Post Message
.form-post-message{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all 0.8s ease-in-out;

  // State: Enabled
  &.enabled{
    pointer-events: auto;
    opacity: 1;
  }

}

form.was-validated{
  .invalid-feedback{
    font-size: 100%;
    margin-bottom: 15px;
    display: block !important;
  }
}

// Displaying the error message for the recaptcha field
.form-group-recaptcha{
  .invalid-feedback{
    display: block !important;
  }
}

