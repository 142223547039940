/*

  Image Block
  ----------------------------------

*/

// Blocks Container
.image-block{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: $paragraph-margin-bottom;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }

  [class^="col"]:last-of-type{
    .image-block-chunk{
      margin-bottom: 0px;
    }
  }

  a[href="#"]{
    pointer-events: none;
  }

}

// Image Chunk
.image-block-chunk{
  margin-bottom: 25px;
  border-radius: 9px;
  background-color: #d8d8d8;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0px;
  }

  >*:last-child{
    margin-bottom: 0px;
  }

  img{
    width: 100%;
  }

}