/*

  Find A Station
  ----------------------------------

*/

.find-a-station{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3.5rem;
  }

}

// Results Container
.find-a-station-result-container{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: $info;
  text-align: center;
  padding: 22px 30px;
  border-radius: 9px;
  margin-bottom: 1.5rem;
  min-height: 175px;

  @include media-breakpoint-up(lg) {
    margin-top: -4.3rem;
  }

  @include media-breakpoint-up(xl) {
    margin-top: -3.4rem;
  }

  h2{
    color: $primary;
    margin-bottom: 10px;
    position: relative;
    font-size: 2.4rem;

    span{
      position: relative;
      z-index: 5;
      background: $info;
      padding: 1px 10px;
      line-height: 1;
    }

    &:before{
      content: "";
      background: url(/web/images/svg/double-signal.svg);
      background-position: 51%;
      height: 23px;
      width: 100%;
      position: absolute;
      top: 9px;
      left: 0;
      z-index: 4;
    }

  }

  h3{
    text-align: left;
    font-weight: 600;
  }

  hr{
    border-color: #fff;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

}

// Result Text
.find-a-station-result-area{
  width: 100%;
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    max-height: 500px;
  }

}
.find-a-station-help-text{
  font-size: .8rem;
  text-align: left;
  color: #fff;
  margin-bottom: 0px;
  font-style: italic;
  font-weight: 600;
}
.find-a-station-result{
  font-size: .8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  position: relative;
  text-align: right;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  line-height: 1.3;
  padding: 5px;

  &:nth-of-type(odd){
    background: rgba(0, 0, 0, 0.10);
  }

  sup{
    top: -0.2em;
    font-size: 100%;
  }

  strong{
    float: left;
    width: 70%;
    text-align: left;
    padding-left: 25px;
  }

  &:before{
    content: "";
    background: url(/web/images/svg/signal-white.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 14px;
    width: 100%;
    position: absolute;
    top: 6px;
    left: 5px;
    z-index: 4;
  }

  &.location{
    &:before{
      background-image: url(/web/images/svg/map.svg);
      height: 17px;
      background-position: 4px 1px;
      top: 12px;
      left: 6px;
    }
  }

  //&.large{
  //  &:before{
  //    display: none;
  //  }
  //  padding: 0px;
  //  font-size: 1rem;
  //  background: none;
  //}

  p{
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: top;
    border-bottom: 1px dotted rgba(255, 252, 252, 0.5);
    width: 100%;
    padding: 8px 0px;
    &:last-of-type{
      border: none;
      margin-bottom: 0px;
    }
  }

  .find-a-station-result-sub{
    margin-left: 25px;
    width: calc(100% - 25px);
    padding: 5px 0px;
    border-color: rgba(240, 240, 240, 0.44);
    font-size: 90%;
    line-height: 1.35;
    strong{
      padding: 0px;
    }
  }

}

// Tabs
.find-a-station-tab-btns{
  display: inline-block;
  vertical-align: top;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  button{
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    padding: 10px 20px;
    background: #e2e2e2;
    border-radius: 0px;
    float: left;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    &.active{
      background: $secondary;
      color:white;
    }

    @include media-breakpoint-up(lg) {
      &:first-of-type{
        border-top-left-radius: 12px;
      }

      &:last-of-type{
        border-top-right-radius: 12px;
      }
    }

  }
}

.find-a-station-tab-content{
  display: none;
  &.active{
    display: block;
  }
}

.print-btn { visibility: hidden }