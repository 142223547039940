/*

  Site Search
  ----------------------------------
  Global search.

*/

// Search
.site-search{
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0px;
  height: 70px;
  background: white;
  //width: 100vw;
  line-height: 65px;
  text-align: left;
  transition: all 0.8s $easeInOutQuint;
  transform: translate3d(calc(100vw - 45px), 0px, 0);

  @include media-breakpoint-up(lg) {
    position: absolute;
    transform: none;
    line-height: 1;
    background: white;
    height: 60px;
    width: 500px;
    top:0px;
    left: 1px;
  }

  // large screen shadow
  &:before{
    content: "";
    position: absolute;
    height: 100%;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
    top: 3px;
    left: 1px;
    z-index: 5;
    opacity: 0;
    transition: all 0.8s ease-in-out;
  }

  // State: Opened
  &.site-search--opened{
    transform: translate3d(15px, 0px, 0);

    @include media-breakpoint-up(lg) {
      transform: translate3d(-420px, 0px, 0);
      &:before{
        opacity: 1;
      }
    }

    .search-icon{
      opacity: 0;
    }

    .cross-icon{
      opacity: 1;
      @include media-breakpoint-up(lg) {
        transform: translateX(25px);
      }
    }

    .site-search-form{
      display: inline-block;
    }

    //@include media-breakpoint-up(lg) {
    //  transform: translate3d(30px, 0px, 0);
    //}

  }

}

.g-search-is-open{
  .site-header-logo,
  .site-header-toggle-menu{
    opacity: 0;
    pointer-events: none;
    @include media-breakpoint-up(lg) {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

// Search toggle
.site-search-toggle{
  padding: 0;
  width: 40px;
  top: 2px;
  position: relative;
  transition: all 0.8s $easeInOutQuint;

  @include media-breakpoint-up(lg) {
    width: 80px;
    padding: 21px 23px;
    top:0px;
    display: inline-block;
    float: left;
    background: white !important;
    z-index: 10;
    border-radius: 0px;
  }

  svg{
    width: 20px;
    height: 20px;
  }

  span{
    transition: all 0.8s $easeInOutQuint;
  }

  .cross-icon{
    position: absolute;
    left:0px;
    opacity: 0;
  }

}

// Search Form (Container)
.site-search-form{
  width: calc(100vw - 45px);
  position: absolute;
  top: 0px;
  left: 45px;

  @include media-breakpoint-up(lg) {
    line-height: 60px;
    width: 415px;
    position: static;
    display: inline-block;
    float: left;
  }

  form{
    width: 100%;
    position: relative;
  }

  input{
    width: 100%;
    border: none;
    padding-left: 2px;
    &:focus{
      outline: 1px dashed white;
    }
  }

  button{
    position: absolute;
    top: 15px;
    right: 25px;
    padding: 10px;
    @include media-breakpoint-up(lg) {
      top: 11px;
    }
  }

  svg{
    width: 15px;
    height: 15px;
  }

}