/*

  Code
  ----------------------------------
  Extends @import "~bootstrap/scss/code";

*/

code, kbd, pre, samp {
  background: $code-bg;
  border-radius: 0px;
}

code, kbd, samp {
  padding: 2px 5px 2px 5px;
  display: inline-block;
  line-height: 1;
}

pre{
  padding-top: 20px;
  code, kbd, samp{
    line-height: 1.3;
  }
}
