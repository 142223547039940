/*

  Media Objects
  ----------------------------------

*/

.media-objects{
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.media-object-item{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 125px;
  margin-bottom: 15px;
  margin-right: 15px;
  text-align: center;

  @include media-breakpoint-up(md) {
    max-width: 145px;
  }

  .media-objects-lg &{

    //margin-right: 3%;
    @include media-breakpoint-up(lg) {
      max-width: 48%;
    }
    &:nth-of-type(2n+2){
      //margin-right: 0;
    }
    a{
      font-size: 1.25rem;
    }
  }

  &:last-of-type{
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }
  }

  a{
    text-decoration: none;
    color: $primary;
    display: block;
    font-weight: bold;
    font-size: 0.85rem;
    line-height: 1.3;
    transition: all .15s ease-in-out;

    &:hover, &:focus{
      img{
        opacity: 0.3;
      }
    }
  }

  // If the anchor tag contains a mailto source..
  // On hover show an email icon...
  a[href*="mailto"]{
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: 59px;
      left: 50%;
      background: url(/web/images/email-icon.png);
      transform: translate(-50%,0%);
      opacity: 0;
      transition: all .4s ease-in-out;
      font-size: 60px;
      line-height: 1;
      width: 50px;
      height: 35px;
      background-size: 50px;
      background-repeat: no-repeat;
    }

    &:hover, &:focus{
      &:before{
        opacity: 1;
      }
    }

  }

  img{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border-radius: 9px;
    overflow: hidden;
    margin-bottom: 5px;
    transition: all .4s ease-in-out;
    //min-height: 150px;
  }

}

.media-object-subtitle {
  display: block;
  font-size: 0.7rem;
  font-weight: normal;
}

