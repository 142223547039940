/*

  Variables
  ----------------------------------
  Custom generic variables.

*/

// Grid
// ----------------------------------
$xxl: 1500px;
$xxxl: 1700px;
