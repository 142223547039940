/*

  baguetteBox.js
  ----------------------------------
  Loading in the third party CSS

*/
@import url('~baguettebox.js/dist/baguetteBox.css');

// Custom Overrides
.baguetteBox-button{
  border-radius: 100%;
  width: 60px !important;
  height: 60px !important;
  text-align: center;
  background: $warning;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%,0);
  }
  polyline, g{
    stroke: rgba(0, 0, 0, 0.5);
  }

  &:hover, &:focus{
    background: $primary;
    polyline, g{
      stroke: rgba(255, 255, 255, 0.5);
    }
  }

}

.baguetteBox-button#previous-button,
.baguetteBox-button#next-button{
  svg{
    transform: translate3d(-50%, -50%,0) scale(0.7);
  }
}