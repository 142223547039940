/*

  Custom Button Link
  ----------------------------------

*/

.custom-btn-link{
  display: block;
  border-radius: 9px;
  padding:  20px 60px 20px 25px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  border-width: 1px !important; //fu bs
  margin-bottom: $paragraph-margin-bottom;
  text-align: left;
  white-space: normal;
  color: $primary;

  svg{
    display: inline-block;
    vertical-align: top;
    width: 25px;
  }

  path{
    fill: currentColor;
    //transition: all .15s ease-in-out;
  }

  &:hover, &:focus{
    color:white;
    path{
      fill: currentColor;
    }
  }

}
.custom-btn-link-icon{
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -3px;
}
.custom-btn-link-arrow{
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
}