/*

  Type
  ----------------------------------
  Custom type rules.

*/


// ----------------------------------


// Unordered list
// Styleguide Reference --- /styleguide/custom#typography-lists
.list-unordered{
  padding: 0px;

  li{
    list-style: none;
    position: relative;
    padding-left: 20px;

    &:after{
      content: '';
      width: 20px;
      height: 11px;
      background: url('/web/images/svg/arrow-right.svg') no-repeat;
      position: absolute;
      top: 5px;
      left: 0px;
    }

  }

  // Spacing
  ul, ol {
    margin-top: 1em;
    padding-left: 10px;
  }

  // If a custom class hasn't been applied to a <ul> on the second level
  >li>ol:not([class]){
    padding-left: 25px;
    li{
      list-style: decimal;
      padding-left: 0px;
      &:after{
        display: none;
      }
    }
  }

  // If the "list-ordered" class is applied to a child <ol>
  .list-ordered{
    li{
      &:after{
        display: none;
      }
    }
  }

}

// Ordered list
// Styleguide Reference --- /styleguide/custom#typography-lists
.list-ordered{
  padding: 0px;
  list-style: none;
  counter-reset: list-ordered-counter;

  li{
    position: relative;
    padding-left: 20px;

    &:before{
      content: counters(list-ordered-counter, ".") ". ";
      counter-increment: list-ordered-counter;
      position: absolute;
      top: 0px;
      left: 0px;
      color: $secondary;
      font-weight: $font-weight-semi-bold;
    }

  }

  // Handling nested ordered lists
  ol{
    list-style: none;
    counter-reset: list-ordered-counter;
    li{
      padding-left: 35px;

      &:before{
        content: counters(list-ordered-counter, ".") ". ";
        counter-increment: list-ordered-counter;
      }

      >ol li{
        padding-left: 45px;
      }

    }
  }

  // Spacing
  ul, ol {
    margin-top: 1em;
    padding-left: 20px;
  }

  // If a custom class hasn't been applied to a <ul> on the second level
  >li>ul:not([class]){
    padding-left: 25px;
    li{
      list-style: circle;
      padding-left: 0px;
      &:before{
        display: none;
      }
    }
  }

  // If the "list-unordered" class is applied to a child <ul>
  .list-unordered{
    li{
      &:before{
        display: none;
      }
    }
  }

}

// Signal heading
.signal-heading{
  color: $primary;
  margin-bottom: 1.7rem;
  position: relative;
  text-indent: -35px;
  padding-left: 35px;

  // Signal
  &:before{
    content: '';
    background-image: url('/web/images/svg/signal.svg');
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  &--noicon {
    &:before {
      display: none;
    }
  }

}

.signal-subheading {
  color: $primary;
  font-size: .9rem;
  margin: 0.5rem 0 2rem;
}
