/*

  News Latest
  ----------------------------------

*/

.news-latest{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3.5rem;
  }

  [class^="col"]:last-of-type{
    .news-latest-item{
      margin-bottom: 0px;
    }
  }

  // eck
  .btn-warning{
    @include media-breakpoint-down(xs) {
      padding: .55rem 2rem;
    }
  }

}

.news-latest-item{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

}

.news-latest-item-media{
  float:left;
  width: 60px;
  height: 60px;
  border: 2px solid $warning;
  border-radius: 100%;
  line-height: 54px;
  text-align: center;

  img{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    right: -1px;
  }

}

.news-latest-item-content{
  float:left;
  width: calc(100% - 60px);
  padding-left: 15px;
}
.news-latest-item-category{
  color: $warning;
  font-size: 0.75rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.news-latest-item-title{
  color: $primary;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 5px;
  a{
    color: currentColor;
    text-decoration: none;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }
}
.news-latest-item-desc{
  font-size: 0.85rem;
  line-height: 1.6;
  margin-bottom: 0px;
}