/*

  Banner
  ----------------------------------

*/

.banner{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 280px;
  padding: 3.5rem 0;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(lg) {
    min-height: 580px;
    margin-bottom: 3.5rem;
    align-items: flex-start;
  }

}

.banner-image{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.banner-content{
  position: relative;
  z-index: 3;
  max-width: 560px;
  color: white;
  font-size: 85%;
  padding: 0px 15px;

    @include media-breakpoint-up(lg) {
        font-size: 100%;
        padding: 0px;
        top: 40px;
    }

  >*:last-child{
    margin-bottom: 0px;
  }

  h1, h2, h3{
    text-transform: uppercase;
  }

  img{
    margin-bottom: 25px;
  }

  .btn{
    margin-bottom: 15px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0px;
    }
  }

}