/*********************************************

    HTML Icons
    A) Menu

********************************************/
.html-icon{
  width: 100%;
  line-height:1;
  display: inline-block;
  vertical-align: top;
}


/*********************************************
    A) Menu
********************************************/
.html-icon-menu{

  span{
    width: 100%;
    height: 2px;
    display: block;
    margin-bottom: 5px;
    background: $primary;
    transition: all 0.4s  $easeInOutQuint;
    &:last-of-type{
      margin-bottom: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover, &:focus{
      span{
        background: $secondary;
      }
    }
  }

  // State: Menu opened
  &.html-icon-menu--opened{
    span:first-of-type{
      transform: translateY(5px) rotate(45deg);
    }
    span:nth-of-type(2){
      opacity: 0;
    }
    span:last-of-type{
      transform: translateY(-9px) rotate(-45deg);
    }
  }

}
