/*

  Sponsors
  ----------------------------------

*/

.sponsors{
  display: inline-block;
  vertical-align: top;
  text-align: left;

  h3{
    color: #939598;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
  }

  ul{
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    position: relative;
    height: 100px;
    width: 150px;
  }

  li{
    list-style: none;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 1;

    &.sponsor--active{
      opacity: 1;
      pointer-events: auto;
      z-index: 2;
    }

  }

  img{
    width: 100%;
    max-width: 150px;
  }

}