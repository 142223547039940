/*

  Text Block
  ----------------------------------

*/

// Blocks Container
.text-block{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: $paragraph-margin-bottom;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }

  [class^="col"]:last-of-type{
    .text-block-chunk{
      margin-bottom: 0px;
    }
  }

  &.text-block-heading-ribbon{
    //margin-bottom: 25px;
  }

  &.text-block-heading-disabled{
    .text-block-chunk{
      h2, h3, h4, h5, h6{
        text-indent: 0px;
        padding-left: 0px;
        &:before{
          display: none;
        }
      }
    }
  }

}

// Text Chunk
.text-block-chunk{
  margin-bottom: 25px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0px;
  }

  >*:last-child{
    margin-bottom: 0px;
  }

  h1, h2, h3, h4, h5, h6{
    color: $primary;
    margin-bottom: 1rem;
    position: relative;
    text-indent: -35px;
    padding-left: 35px;

    // Signal
    &:before{
      content: '';
      background-image: url('/web/images/svg/signal.svg');
      height: 25px;
      width: 25px;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      margin-top: 2px;

      .text-block-heading-ribbon &{
        background-image: url('/web/images/svg/ribbon.svg');
      }

    }

  }

  h1{
    margin-bottom: 1.7rem;
  }

  p, li{
    font-size: 0.9rem;
    line-height: 1.5;

    // Remove nested spacing... cuz word'
    p{
      margin-bottom: 0px;
    }

  }

  li{
    margin-bottom: .25em;
  }

  // List items after a paragraph tag get nudged upwards
  dl, ol, ul {
    padding-left: 1rem;
    margin-top: -20px;
  }

  .lead{
    font-size: 1rem;
    line-height: 1.4;
  }



  // Style: Dark
  .text-block-dark &{
    border-radius: 9px;
    border: 1px solid #243c75;
    background-color: #e6e7e8;
    padding: 35px 20px 30px 20px;
    @include media-breakpoint-up(lg) {
      padding: 35px 25px 35px 25px;
      height: 100%;
    }
  }

}