/*

  News List
  ----------------------------------

*/

.news-list{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: $paragraph-margin-bottom;
}

.news-list-item{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 2.5rem 0px 2.5rem 0px;
  border-bottom: 1px solid $gray-100;

  &:first-of-type{
    padding-top: 0px;
  }

  &:last-of-type{
    border-bottom: none;
    padding-bottom: 0px;
  }

  p{
    font-size: 0.85rem;
    line-height: 1.6;
  }

}

.news-list-item-meta{
  font-size: 0.75rem !important;
  margin-bottom: 15px;
  color: $gray-400;
}

.news-list-item-title{
  color: $primary;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 15px;
  a{
    color: currentColor;
    text-decoration: none;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }
}