/*

  Type
  ----------------------------------
  Extends @import "~bootstrap/scss/type";

*/

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Ensure the correct bold font weight is applied
strong, b, dt{
  font-weight: $font-weight-black;
}

// Ensure the correct base font weight is applied
small, .small {
  font-weight: $font-weight-light;
  display: inline-block;
  line-height: 1.2;
}

li, p{
  small{
    line-height: 1.2;
  }
}

// Optimising list spacing
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  padding-left: $paragraph-margin-bottom;
}

dl{
  padding-left: 0px;
}

li{
  margin-bottom: 0.75em;
  &:last-of-type{
    margin-bottom: 0px;
  }
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  margin-top: 0.75em;
}

a{
  word-break: break-word;
}

// Updating <hr/> styles
hr {
  margin-top: 0px;
  margin-bottom: $paragraph-margin-bottom / 1.5;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
  display: inline-block;
  width: 100%;
}


// Optimising display classes
.display-1 {
  font-size: calc(#{$display1-size} / 2);
  @include media-breakpoint-up(lg) {
    font-size: $display1-size;
  }
}
.display-2 {
  font-size: calc(#{$display2-size} / 1.5);
  @include media-breakpoint-up(lg) {
    font-size: $display2-size;
  }
}
.display-3 {
  font-size: $display3-size;
  @include media-breakpoint-up(lg) {
    font-size: $display3-size;
  }
}
.display-4 {
  font-size: $display4-size;
  @include media-breakpoint-up(lg) {
    font-size: $display4-size;
  }
}