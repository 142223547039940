/*

  Gallery
  ----------------------------------

*/

.gallery{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
}

.gallery-item{
  background: #d8d8d8;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  height: 165px;
  border-radius: 9px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 13px;
  margin-right: 7px;
  margin-left: 7px;

  img{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    font-family: 'object-fit: cover; object-position: center top;';
  }

}