/*

  Image Carousel
  ----------------------------------

*/

.image-carousel{
  background: #d8d8d8;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  //height: 435px;
  text-align: center;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3.5rem;
  }

}

.image-carousel-item{
  >img{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-height: 435px;
    object-fit: cover;
    object-position: center top;
    font-family: 'object-fit: cover; object-position: center top;';
  }
}