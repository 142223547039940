/*

  Utilities
  ----------------------------------
  Custom generic helpers.

  1. Triangle
  2. Spacing
  3. Position Relative/Absolute
  4. Display

*/


// ----------------------------------
// 1. Triangle
// ----------------------------------
@mixin triangle ($direction, $color, $height, $width) {
  width: 0;
  height: 0;
  @if $direction == 'bottom' {
    border-top: $height solid $color;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
  }
  @if $direction == 'bottom-right' {
    width: 0;
    height: 0;
    border-bottom: $height solid $color;
    border-left: $width solid transparent;
  }
  @if $direction == 'left' {
    border-top: $height solid transparent;
    border-bottom: $height solid transparent;
    border-right: $width solid $color;
  }
  @if $direction == 'right' {
    border-top: $height solid transparent;
    border-bottom: $height solid transparent;
    border-left: $width solid $color;
  }
  @if $direction == 'top' {
    border-bottom: $height solid $color;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
  }
  @if $direction == 'top-left' {
    width: 0;
    height: 0;
    border-top: $height solid $color;
    border-right: $width solid transparent;
  }
}


// ----------------------------------
// 2. Spacing
// ----------------------------------

// Min Height: Set a min height
@for $i from 1 through 10 {
  .mh-#{$i}0 {
    min-height: #{$i + '0vh'} !important;
  }
}

// Min Height: Breakpoint up $lg
@for $i from 1 through 10 {
  .mh-lg-#{$i}0 {
    @include media-breakpoint-up(lg) {
      min-height: #{$i + '0vh'} !important;
    }
  }
}

// Min Height: Breakpoint up $xl
@for $i from 1 through 10 {
  .mh-xl-#{$i}0 {
    @include media-breakpoint-up(xl) {
      min-height: #{$i + '0vh'} !important;
    }
  }
}


// ----------------------------------
// 3. Position Relative/Absolute
// ----------------------------------
// Really gross position:relative/absolute classes :(
.ps-abs-right-0{
  position: absolute;
  right:0px;
  z-index: 2;
}
.ps-abs-right-bottom-n15{
  position: absolute !important;
  right: -15px;
  bottom: -15px;
  z-index: 2;
}

.ps-abs-2{
   position: absolute;
   z-index: 2;
}
.pos-t-n40{
  top:-40px;
}

.ps-rel-3{
  position: relative;
  z-index: 3;
}


// ----------------------------------
// 4. Display
// ----------------------------------
// Really gross display classes :(
.d-xxl-block{
  @media (min-width: $xxl){
    display: block !important;
  }
}
.d-xxxl-block{
  @media (min-width: $xxxl){
    display: block !important;
  }
}


// ----------------------------------
// 4. Aspect Ratio
// ----------------------------------
.aspect-ratio-box {
  position: relative;
  width: 100%;
  overflow: hidden;

  &:after{
    content: "";
    display: table;
    clear: both;
  }

  // The initial ratio
  &:before{
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%; /* initial ratio of 1:1 */
  }

  /* Other ratios */
  &.ratio2_1:before{
    padding-top: 50%;
  }
  &.ratio1_2:before{
    padding-top: 200%;
  }
  &.ratio4_3:before{
    padding-top: 75%;
  }
  &.ratio16_9:before{
    padding-top: 56.25%;
  }
  &.ratio_weird:before{
    padding-top: 120%;
    @include media-breakpoint-up(xl) {
      padding-top: 90%;
    }
  }

  // The content
  .aspect-ratio-content{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

}


// Spacing
// ----------------------------------
// Remove margin bottom from the last element inside structure
// margin-bottom-last-child-0
.mb-lc-0 {
  >*:last-child {
    margin-bottom: 0 !important;
  }
}
.mb-lg-lc-0 {
  >*:last-child {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0 !important;
    }
  }
}
