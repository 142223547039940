/*

  Radio Awards Banner
  ----------------------------------

*/

.radio-awards-banner{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: url('/web/images/radio-awards-banner-bg.jpg');
  background-size: cover;
  background-position: 47% 58%;
  margin-bottom: 1.5rem;
  padding: 2rem 0;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }

}

.radio-awards-banner-main{
  @include media-breakpoint-up(lg) {
    >*:last-child{
      margin-bottom: 0px;
    }
  }
}
.radio-awards-banner-sidebar{

  .call-to-action {
    margin-bottom: 1.5rem;
  }

  >*:last-child{
    margin-bottom: 0px !important;
  }

}