/*

  Call to Action
  ----------------------------------

*/

.call-to-action{
  display: flex;
  vertical-align: top;
  width: 100%;
  text-align: center;
  margin-bottom: 2.5rem;
  border-radius: 9px;
  padding: 20px 15px !important;
  position: relative;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  box-shadow: inset 0px 0px 0px 1px currentColor;

  @include media-breakpoint-up(lg) {
    margin-bottom: 3.5rem;
  }

  .btn{
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    position: relative;
    z-index: 5;
  }

  .btn-outline-dark{
    border-color: white !important;
    &:hover, &:focus{
      background: rgba(0, 0, 0, 0.25);
    }
  }

  // State: Large
  &.call-to-action-lg{
    background: $gray-500 !important;
    min-height: 400px;
  }

}

.call-to-action-heading{
  position: relative;
  z-index: 5;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  margin-bottom: 15px;
  width: 100%;
}

.call-to-action-desc{
  position: relative;
  z-index: 5;
  color: #fff;
  font-size: 1.15rem;
  margin-bottom: 15px;
  line-height: 1.4;
  width: 100%;
}

.call-to-action-img{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
  border-radius: 9px;
}