/*

  Print
  ----------------------------------
  Print Styles

*/

@media print {

  body{
    background: white;
  }

  .banner,
  .site-header,
  .site-footer,
  .find-a-station-tab-btns,
  .find-a-station-filters,
  .site-main-menu{
    display: none;
  }

  .signal-heading{
    padding: 0;
    text-indent: 0;
    margin-bottom: 10px;
    font-size: 20px;
    color: black;
    &:before{
      display: none;
    }
  }

  .find-a-station-result-container{
    padding: 0px;
    height: auto;
    background: white;
    color: black;
    text-align: left;
    margin: 0px;

    h2{
      background: white;
      font-size: 22px;
      color:black;
      &:before{
        display: none;
      }
      span{
        background: white;
        padding: 0px;
      }
    }

    hr {
      border-color: #000;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

  }

  .col-lg-8 > p {
    display: none;
  }

  .text-primary,
  .text-white{
    color: black !important;
  }

  .find-a-station-result-area{
    max-height: none;
    overflow: auto;
    line-height: 1.2;
  }

  .find-a-station-result{
    line-height: 1.1;
    font-size: 12px;
    background: none!important;
    color: #000!important;
    border-bottom: 1px solid #adadad;
    padding: 5px;
    width: 45%;
    margin-right: 2%;
    &:nth-of-type(2n+2){
      margin-right: 0%;
    }

    strong{
      padding-left: 0px;
      width: auto;
    }

    &:before{
      display: none;
    }

  }

  .row.mb-3{
    display: none;
  }

  .js-fas-result-area{
    small{
      font-size: 100%;
    }
    h3{
      font-size: 18px;
    }
    br{
      display: none;
    }
  }



}